import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


export const fetchNavigate = createAsyncThunk(
    'navigate/fetchNavigate',
    async ({ email, password, role }, { rejectWithValue, dispatch, getState }) => {
        //console.log(getState());
        try {

        } catch (error) {

        }

    }
)

const setError = (state, action) => { //обработка ошибки
    state.status = 'rejected';
    state.error = action.payload;
}

const navigate = createSlice({
    name: 'navigate',
    initialState: {
        scroll: { catalog: false, tech: false },
        menu: false,
        registr: false,
        login: false,
        error: null,
        startTechMult: [true, true, true, true, true]
    },
    reducers: {

        goScroll(state, action) {

            return {
                ...state, scroll: { ...action.payload }
            }
        },
        goMenu(state, action) {

            return {
                ...state, menu: action.payload
                
            }
        },

        goRegLog(state, action) {

            return {
                ...state, ...action.payload
                
            }
        },
        registrError(state, action) {
            return {
                ...state, error: action.payload.error
            }
        },
        startMult(state, action) {
            return {
                ...state, startTechMult: [...action.payload]
            }
        }
    },
    extraReducers: {
        [fetchNavigate.pending]: (state, action) => {
            state.status = 'loading';

        }, // метод жизненного цикла - загрузка 
        [fetchNavigate.fulfilled]: (state, action) => {
            state.status = null;
        }, // метод жизненного цикла - успешное получение данных
        [fetchNavigate.rejected]: setError,
        // метод жизненного цикла - неполадка

    }
})

export const { goScroll, goRegLog, goMenu, startMult } = navigate.actions
export default navigate.reducer
import React from "react";
import s from "./partners.module.css";
import Slider from "./Slider";
import SliderBack from "./SliderBack";
export default function Partners() {

  return (
    
      <div className={s.partners}>

        <div className={s.container}>
          
        <h2 className={s.partnersOur}>Наши партнеры</h2>
 </div>
          <Slider />
          <SliderBack />
     
    </div>
  );
}

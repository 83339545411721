import React, { useState } from "react";
import { useForm } from "react-hook-form";
import s from "../log/formLog.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectStatus } from "../../../redux/selectors";
import { fetchReg } from "../../../redux/slices/regSlice";

export default function Regin() {
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const [passw, setPass] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    reset();

    if (status !== "loading") {
      dispatch(
        fetchReg({
          email: data.email,
          password: data.password,
          tel: data.tel,
          company: data.company,
          unp: data.unp,
          role: "USER",
        })
      );
    }
  };
  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={s.mailForm}>
        <label className={s.email}>
          E-mail &#8432;
          <input
            className={s.mail}
            {...register("email", {
              // validate
              required: "Поле обязательно к заполнению",
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "Не корректный e-mail",
              },
            })}
            placeholder="Введите ваш E-mail"
          />
        </label>
         <div>{errors.email && <p>{errors.email.message || "Ошибка"}</p>}</div>   
      </div>
  
      <div className={s.passvordForm}>
        <label className={s.passvord}>Пароль &#8432;</label>
        <input
          className={s.mail}
          {...register("password", {
            // validate
            onBlur: (e) => {
              setPass(e.target.value);
            },
            required: "Поле обязательно к заполнению",
            maxLength: {
              value: 12,
              message: "Не более 12 символов",
            },
            minLength: {
              value: 4,
              message: "Не менее 4 символов",
            },
          })}
          type="password"
          autoComplete="off"
          placeholder="Введите ваш пароль"
        />
          <div>{errors.password && <p>{errors.password.message || "Ошибка"}</p>}</div> 
      </div>
   

      <div className={s.passvordForm}>
        <input
          className={s.mail}
          {...register("passwordConf", {
            // validate
            required: "Поле обязательно к заполнению",

            validate: (value) => {
              if (value !== passw) {
                return "Пароли не совпадают";
              }
            },
          })}
          type="password"
          autoComplete="off"
          placeholder="Подтвердите пароль"
        />
           <div> {errors.passwordConf && (
          <p>{errors.passwordConf.message || "Ошибка"}</p>
        )}</div>
      </div>
    
      <div className={s.phoneBox}>
        <label className={s.phone}>
          Телефон &#8432;
          <input
            placeholder="+375xxxxxxxxx"
            className={s.mail}
            {...register("tel", {
              // validate
              required: "Поле обязательно к заполнению",
              pattern: {
                value: /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
                message: "Не корректный телефон",
              },
            })}
          />
        </label>
        <div>{errors.tel && <p>{errors.tel.message || "Ошибка"}</p>}</div>
      </div>
      <div className={s.phoneBox}>
        <label className={s.phone}>
          Компания &#8432;
          <input
            placeholder="Название компании"
            className={s.mail}
            {...register("company", {
              // validate
              required: "Поле обязательно к заполнению",
            })}
          />
        </label>
        <div>
          {errors.company && <p>{errors.company.message || "Ошибка"}</p>}
        </div>
      </div>
      <div className={s.phoneBox}>
        <label className={s.phone}>
          УНП &#8432;
          <input
            placeholder="Введите ваш УНП"
            className={s.mail}
            {...register("unp", {
              // validate
              required: "Поле обязательно к заполнению",
              maxLength: {
                value: 9,
                message: "Не корректный УНП",
              },
              minLength: {
                value: 9,
                message: "Не корректный УНП",
              },
            })}
          />
        </label>
        <div>{errors.unp && <p>{errors.unp.message || "Ошибка"}</p>}</div>
      </div>

      <button className={s.formLogBtn} type="submit">Отправить</button>
    </form>
  );
}

import axios from "axios";
import { urlServer } from "../config";

//let AUTH_TOKEN =  localStorage.getItem("token");

const instance = axios.create({
    baseURL: urlServer
    //baseURL: 'http://192.168.0.103:5000/premex/'
})
//instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;



export default class CatalogService {

    static async getCatalogList(token) {
        instance.defaults.headers.common['Authorization'] = token;
        
        const result = await instance.get('catalog/getinit');
        
        return result.data
    } 

    static async getCatalog() {
                
        const result = await instance.get('catalog/getall');
        
        return result.data
    }



   
    static async getDevice(name) {
       
        const result = await instance.post('device/getone', {name});
      
        return result.data
    }
    static async uploadFile(fileName, catalog) {

       const file = await instance.post('device/download', {fileName, catalog }, { responseType: 'blob' })
           
        return file

    }
    static async getFiles() {
        
        const files = await instance.get('device/getdocs')
            
         return files.data
 
     }
}



import React, { useEffect, useState } from "react";

import s from "./subcatalog.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentCatalog,
  selectCatalog,
  selectActSubCat,
  selectUndCat,
} from "../../../redux/selectors";
import {
  catalogActReducer,
  changeCurrProd,
  subCatalAct,
} from "../../../redux/slices/catalogSlice";
import { useNavigate } from "react-router-dom";
import { HiXMark } from "react-icons/hi2";

export default function Subcatalog({ size }) {
  const dispatch = useDispatch();
  const actCatSub = useSelector(selectActSubCat);
  const undCatalog = useSelector(selectUndCat);
  const currCat = useSelector(selectCurrentCatalog);
  const mainCatalog = useSelector(selectCatalog);
  const [showIndex, setShowIndex] = useState(0);
  const [mobile, setMobile] = useState("-25%");
  const [catalog, setCatalog] = useState([]);
  const navigate = useNavigate();
  const onClick = () => {
    dispatch(subCatalAct([false]));
    dispatch(catalogActReducer([null, 0, 0]));
  };
  let catalogProd = [];
  const openProd = (e) => {
    setShowIndex(Number(e.target.id));
  };

  useEffect(() => {
    if (size < 801) {
      setMobile("-50%");
    }
  }, [size]);

  useEffect(() => {
    if (currCat[0] !== null) {
      setCatalog(undCatalog[currCat[0]][currCat[1]].subCatalog);
    } else setCatalog([]);
  }, [currCat, undCatalog]);

  if (actCatSub[0] & (catalog.length < 3)) {
    catalogProd = catalog.map((value, index) => {
      return (
        <div className={s.subcatalogItems} key={value[0] + index + "cat"}>
          <h3>{value[0].length > 0 && value[0]}</h3>
          <div className={s.machwater}>
            {value.map((v, i) => {
              if (i !== 0) {
                return (
                  <div key={v + i + "sub"}>
                    <div
                      onClick={() => {
                        dispatch(changeCurrProd(v));
                        dispatch(
                          catalogActReducer([currCat[0], currCat[1], index])
                        );
                        navigate("/product");
                      }}
                      className={s.catalogMachWater}
                    >
                      {v}
                    </div>
                  </div>
                );
              }
              return <div key={v + i + "sub0"}></div>;
            })}
          </div>
        </div>
      );
    });
  }
  if (actCatSub[0] & (catalog.length > 2)) {
    catalogProd = catalog.map((value, index) => {
      if (index !== showIndex) {
        return (
          <div className={s.subcatalogItems} key={value + index + "cat1"}>
            <div className={s.subcatalogItem} style={{ display: "flex" }}>
              <h3>{value[0]}</h3>
              <h3 id={index} onClick={openProd} style={{ cursor: "pointer" }}>
                {" "}
                +{" "}
              </h3>
            </div>
          </div>
        );
      }

      return (
        <div className={s.subcatalogItems} key={value + index + "cat2"}>
          <h3>{value[0]}</h3>
          <div className={s.machwater}>
            {value.map((v, i) => {
              if (i !== 0) {
                return (
                  <div key={v + i + "sub1"}>
                    <div
                      onClick={() => {
                        dispatch(changeCurrProd(v));
                        dispatch(
                          catalogActReducer([currCat[0], currCat[1], index])
                        );
                        navigate("/product");
                      }}
                      className={s.catalogMachWater}
                    >
                      {v}
                    </div>
                  </div>
                );
              }
              return <div key={v + i + "sub1"}></div>;
            })}
          </div>
        </div>
      );
    });
  }

  return (
    <div
      className={s.subcatalog}
      style={
        actCatSub[0]
          ? { marginLeft: mobile, display: "block" }
          : { marginLeft: "0%" }
      }
    >
      <div className={s.subcatalogТор}>

        <h3>{mainCatalog[currCat[0]]}</h3>

        <HiXMark onClick={onClick} cursor={'pointer'} size={40} />
      </div>

      <div className={s.container}>
        <div className={s.catalogWater}>{catalogProd}</div>
      </div>
    </div>
  );
}

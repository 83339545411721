import React from "react";
import s from "./popUp.module.css";

function PopUp() {
  return (
    <div className={s.containerPopup}>
      <div className={s.popup}>
        <p className={s.popupText2}>Уважаемые Партнёры и Коллеги! </p>
        <p className={s.popupText2}>График работы ООО "ПремексБел" 09.08.2024:</p>
        <p className={s.popupText3}>  с 8.45 до 13.30</p>
       

        {/* <p className={s.popupText2}>Уважаемые покупатели!</p>
            <p className={s.popupText1}>Поздравляем вас с Рождеством и Новым годом!</p>
            <p className={s.popupText2}>График работы ООО "ПремексБел" в январе 2024г:</p>
            <p className={s.popupText3}>с 01.01.2024г. по 07.01.2024г. - выходной</p>
            <p className={s.popupText4}>По всем возникающим вопросам обращайтесь по телефонам:</p>
            <p className={s.popupText4}>+375 44 512-81-12 Надежда, +375 29 696-31-91 Татьяна, +375 44 503-05-13 Елена</p>

             <p className={s.popupText2}>Уважаемые Партнёры и Коллеги! </p>
               <p className={s.popupText2}>График работы ООО "ПремексБел":</p>
            <p className={s.popupText3}> 18.05.2024 – выходной</p>
          <p className={s.popupText3}> с 20 мая 2024 – стандартный рабочий график:</p>

          <p className={s.popupText3}> Понедельник - четверг 8:45 – 17:15 Пятница 8:45 – 17:15 Суббота,
          воскресенье - выходной.</p>
            <p className={s.popupText4}>По всем возникающим вопросам обращайтесь по телефонам:</p>
  <p className={s.popupText4}>+375 44 512-81-12 Надежда, +375 29 696-31-91 Татьяна, +375 29 374-20-65 Оксана</p>*/}
      </div>
    </div>
  );
}

export default PopUp;

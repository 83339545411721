import React, { useEffect } from "react";
import s from "./technologyCardLora.module.css";
import Futter from "../futter/Futter";
import CBKX_1_IoT from "../../image/CBKX_1_IoT.png";
import CBKG_1_IoT from "../../image/CBKG_1_IoT.png";
import IoT_М from "../../image/IoT_М.png";
import IoT_U from "../../image/IoT_U.png";
import IP_15_60 from "../../image/IP_15_60.png";
import Schetchik_imp_IoT from "../../image/Schetchik_imp_IoT.png";
import IoT from "../../image/IoT.png";
import { useDispatch, useSelector } from "react-redux";
import Mult from "./Mult";
import { useNavigate } from "react-router-dom";
import { catalogActReducer, changeCurrProd } from "../../redux/slices/catalogSlice";
import { selectStart } from "../../redux/selectors";
import { startMult } from "../../redux/slices/navigateSlice";
import { HiOutlineChevronRight } from "react-icons/hi2";

const TechnologyCardIot = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const start = useSelector(selectStart)


  const prod = [['Счетчик холодной воды крыльчатый СВКХ-15к c радиомодулем IoT', 'СВКХ-15к с радиомодулем IoT',
  CBKX_1_IoT, [0, 0, 0]],
  ['Счетчик горячей воды крыльчатый СВКГ-15к с радиомодулем IoT', 'СВКГ-15к с радиомодулем IoT',
  CBKG_1_IoT, [0, 0, 0]],

  ['Теплосчетчик механический Экохит М с радиомодулем IoT DN15', 'Экохит М с радиомодулем IoT DN15',
  IoT_М, [1, 0, 0]],
  ['Теплосчетчик ультразвуковой Экохит У с радиомодулем IoT DN15', 'Экохит У с радиомодулем IoT DN15',
  IoT_U, [1, 1, 0]],
  ['Приёмный радиомодуль Пульсар IoT', 'Приёмный радиомодуль Пульсар IoT',
  IoT, [1, 1, 0]],
  
  ['Счетчик импульсов-регистратор Пульсар 3-канальный с радиоинтерфейсом IoT', 'Счетчик импульсов-регистратор Пульсар 3-канальный с радиоинтерфейсом IoT',
  Schetchik_imp_IoT, [4, 0, 0]],
  ['Источник питания ИП15-60, 3А', 'Источники питания Пульсар',
  IP_15_60, [5, 0, 0]],

  ]
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {

    setTimeout(() => {
      if (start[1]) {
        let a = [...start]
        a.splice(1, 1, false)
        dispatch(startMult(a))
      }
    }, 7000)
  }, [dispatch, start]);
  const onClick = (catalog, product) => {

    dispatch(catalogActReducer(catalog))
    dispatch(changeCurrProd(product))
    navigate('/product')
  }
  const products = prod.map((el, i) => {
    return (
      <div onClick={() => {
        onClick(el[3], el[1])
      }} key={el[1]} className={s.blockCatalog}>
        <p className={s.numberPoductCatalog}>{'0' + (i + 1)}</p>
        <p className={s.poductCatalog}>
          {el[0]}
        </p>
        <img className={s.svkh} src={el[2]} alt="" />
      </div>
    )
  })

  return (
    <div>
      {(start[1] && !start[1]) ? <Mult close={() => {       // убрать !start[1]
        start.splice(1, 1, false);
        dispatch(startMult(start))
      }} name={'picStartIot'} /> :
        <div className={s.technologyCard}>
          <div className={s.container}>
            <div className={s.blockTree} >
              <span onClick={() => navigate(-1)} className={s.item}>
                Технологии
              </span>
              <HiOutlineChevronRight />
              <span className={s.item} >
                IoT
              </span>
            </div>

            <h2>IoT — интернет вещей</h2>
            <div className={s.blockTop}>

              <div className={s.blockTop}>
                <Mult name={'picIot'} />
              </div>

            </div>

            <h3 className={s.hArhitect}>Архитектура сети IoT</h3>
            <p className={s.textArhitect}>Сбор и передача данных с помощью технологии IoT включает следующие элементы:</p>
            <div className={s.blockArhitect}>

              <div className={s.listArhitect}>
                <p className={s.textLeft}>Конечные устройства</p>
                <p className={s.textRight}>Счетчики энергоресурсов (счетчики воды, счетчики тепла), которые собирют данные из своей среды. </p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Шлюз</p>
                <p className={s.textRight}>После того как данные собраны, они отправляются в облако с помощью устройств (средств) связи. Есть множество способов перенаправить информацию в облако: сотовая и спутниковая связь, Wi-Fi, Bluetooth, LPWAN или Ethernet.</p>

              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Сетевой сервер </p>
                <p className={s.textRight}>Это компонент сетевой инфраструктуры, который обрабатывает и хранит данные, передаваемые конечными устройствами и шлюзами LoRa. Сервер может быть размещен в облаке или в локальной сети, и обычно на нем работает специализированное программное обеспечение, которое позволяет ему получать, декодировать и обрабатывать данные, передаваемые по сети LoRa.</p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Сервер приложений</p>
                <p className={s.textRight}>Сервер приложений заимодействует с конечными устройствами, отслеживает их состояние и контролирует работу. Является конечной точкой сбора данных. </p>
              </div>
            </div>
            <h3 className={s.hTehnology}>Предлагаемая продукция</h3>
            <div className={s.listCatalog}>
              {products}

              <p className={s.textTarif}> * Для организации сбора данных с приборов учета с дистанционным съемом ООО "ПремексБел" рекомендует приобрести: SIM-карту мобильного оператора МТС с тарифным планом "Интернет вещей" либо SIM-карту мобильного оператора А1 с тарифным планом "Телеметрия NBiot"</p>
              <p className={s.textSlogan}>Свяжитесь с нами для получения предложения или информации!</p>
            </div>
          </div>

        </div>}
      {!start[1] && <Futter />}
    </div>
  );
};

export default TechnologyCardIot;

import React, { useEffect, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import MyButton from "../UI/button/MyButton";
import s from "./basket.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBasShow,
  selectBasket,
  selectVolBas,
} from "../../redux/selectors";
import {
  basketShowReducer,
  changeBasket,
  fetchBasket,
  orderShowReducer,
} from "../../redux/slices/basketSlice";

const Basket = () => {
  const dispatch = useDispatch();
  const showBasket = useSelector(selectBasShow);
  const basket = useSelector(selectBasket);
  const volBasket = useSelector(selectVolBas);
  const [volume, setVolume] = useState([]);
  useEffect(() => {
    setVolume([...volBasket]);
  }, [volBasket]);

  const changeVolume = (i, m) => {
    let newVolum = volume.map((value, index) => {
      if (index === i) {
        if ((m === 0) & (value > 1)) {
          return value - 1;
        }
        if (m === 1) return value + 1;
      }
      return value;
    });
    setVolume([...newVolum]);
  };

  const changeProd = (i) => {
    let index = 0;
    let newBasket = [];
    basket.forEach((element) => {
      if (index !== i) {
        newBasket.push([element[0], volume[index]]);
      }
      index++;
    });

    dispatch(changeBasket([...newBasket]));
    dispatch(fetchBasket([...newBasket]));
  };
  const closeBas = () => {
    dispatch(basketShowReducer(false));
    changeProd();
  };
  const sendBasket = () => {
    closeBas();
    dispatch(orderShowReducer(true));
  };
  const newBasket = basket.map((value, index) => {
    return (
      <div key={value[0]} className={s.descript}>
        <div className={s.produckBasket}>
          <div className={s.blockTextBasket}>
            <p className={s.name}>{value[0]}</p>
            <div className={s.blockTotal}>
              <p className={s.textTotal}>Количество:</p>
              <div className={s.wrapperAdd}>
                <div className={s.add}>
                  <div
                    className={s.minus}
                    onClick={() => changeVolume(index, 0)}
                  >
                    {" "}
                    -
                  </div>
                  <div className={s.amount}>{volume[index]}</div>
                  <div
                    className={s.plus}
                    onClick={() => changeVolume(index, 1)}
                  >
                    {" "}
                    +
                  </div>
                </div>
              </div>
            </div>
            <div className={s.del}>
              <p onClick={() => changeProd(index)} className={s.card}>
                Удалить
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={s.popBasket}>
      
      <div className={showBasket ? s.basket : s.basketHid}>
       <HiXMark
        onClick={closeBas}
        className={s.closeBas}
        cursor={"pointer"}
        size={30}
        color={"#000"}
      />
        <div className={s.blockTop}>
          <span className={s.basketTitle}>Моя корзина </span>
        </div>

        <div className={s.blockProduct}>
          {newBasket}

          {basket.length > 0 && (
            <MyButton
              onClick={sendBasket}
              className={s.basketBtn}
              type="submit"
            >
              Отправить
            </MyButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Basket;

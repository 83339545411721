import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from '../log/formLog.module.css'

import { HiXMark } from "react-icons/hi2";
import {
  registrReducer,
  registrError,
  registrChangeStatus,
} from "../../../redux/slices/regSlice";
import {
  selectError,
  selectStatus,
} from "../../../redux/selectors";
import Regin from "./Regin";

const Reg = ({ style, close }) => {
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  useEffect(() => {

    if (status === 'ok') {
      close();
      dispatch(registrChangeStatus(null))
    }
  }, [status, close, dispatch])

  const errom = () => {
    dispatch(registrReducer({ email: "", role: "" }));
    return dispatch(registrError({ error: null }));
  };
  return (
    <div style={style} className={s.logForm}>
      <HiXMark className={s.closeReg} onClick={close} />
      
      <div className={s.blockTop}>
        <span className={s.helloRegisrt}>Регистрация</span>
      </div>

      {error && (
        <>
          <div className={s.errorLogin}>
            <div className={s.errorText}>{error}</div>

            <HiXMark className={s.close} onClick={errom} />
          </div>

        </>
      )}


      <Regin />
    </div>
  );
};
export default Reg;

import React from "react";
import { NavLink } from "react-router-dom";
import s from "./menuBurger.module.css";
import { useDispatch } from "react-redux";
import { goMenu } from "../../../redux/slices/navigateSlice";
import { HiOutlineBars3 } from "react-icons/hi2";

const MenuBurger = () => {
  const dispatch = useDispatch();
  
  return (
    <div>
      <div className={s.menuBurger}>
        <div className={s.container}>
          <div className={s.wrapper}>
            <div className={s.burger}>
            <HiOutlineBars3 onClick={() => {dispatch(goMenu(false))}}/>
            
              <div className={s.menuBurgerBlock}>
                <NavLink className={s.menuBurgerBlockItem} to="/about">
                  {" "}
                  О компании{" "}
                </NavLink>

                <NavLink onClick={() => {dispatch(goMenu(false))}} className={s.menuBurgerBlockItem} to="/catalog">
                  Каталог
                </NavLink>

                <NavLink onClick={() => {dispatch(goMenu(false))}} className={s.menuBurgerBlockItem} to="/technologes">
                  Технологии
                </NavLink>

                <NavLink onClick={() => {dispatch(goMenu(false))}} className={s.menuBurgerBlockItem} to="/documents">
                  Документация
                </NavLink>

                <NavLink onClick={() => {dispatch(goMenu(false))}} className={s.menuBurgerBlockItem} to="/contact">
                  Контакты
                </NavLink>
            
              </div>
                  <div className={s.menuContact}>
                  <div className={s.menuLine}></div>
                <p className={s.menuMail}>info@premexbel.by</p>
                <p className={s.menuMail}>+375 29 305-68-33</p>
                <p className={s.menuMail}>+375 17 299-55-23</p>
                  </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBurger;

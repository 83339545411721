import React, { useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import s from "./page2.module.css"

let i = [0, 0, 0];
let timer;
export default function Page2() {
  const [start, setStart] = useState(i);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
const testRef = useRef(null);
  if (inView) {
    timer = setInterval(() => {
      if (i[0] < 28) {
        i[0]++;
        setStart([i[0], i[1], i[2]]);
        
      }
      if (i[1] < 50) {
        i[1]++;
        setStart([i[0], i[1], i[2]]);
        
      }
      if (i[2] < 1000) {
        i[2]++;
        setStart([i[0], i[1], i[2]]);
        
      }
    }, 100);
  }
  if (!inView && timer) {
    clearInterval(timer);
  }

  //console.log(ref.current);
  return (
    <div>
      
      <div className={s.page2}>
        <div className={s.container}>
          <div className={s.about}>
          <div className={s.pageAbout}>
            <p className={s.text1}>Предлагаем <span className={s.text4}>лучшие готовые решения</span></p> 
            <p className={s.text2}>c точки зрения производительности,</p>
            <p className={s.text3}>надежности, стоимости и эксплуатации</p>
            <p className={s.text4}> с технической поддержкой.</p>    
          </div>
          <div ref={ref} className={s.pageAboutNumbers}>
            <div className={s.pageAbout}>
              <div className={s.numberBlock}>
              <span className={s.textOne}>Более</span>
              <span className={s.number}>{start[0]}</span>
            </div>

              <span className={s.textTwo}> лет работаем на рынке Беларуси</span>
            </div>
            <div className={s.pageAbout}>
              <div className={s.numberBlock}>
              <span className={s.textOne}>Более</span>
                <span className={s.number}>{start[1]}</span>
              </div>

              <span className={s.textTwo}>завершенных проектов</span>
            </div>
            <div className={s.pageAbout}>
              <div className={s.numberBlock}>
              <span className={s.textOne}>Более</span>
                <span className={s.number}>{start[2]}</span>
              </div>

              <span className={s.textTwo}>довольных клиентов</span>
            </div>
          </div>
          <div ref={testRef} className={s.command}>Наша команда</div>
          </div>
          
        </div>

      </div>
    </div>
  );
}

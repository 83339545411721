import axios from "axios";
import { urlServer } from "../config";
//let AUTH_TOKEN =  localStorage.getItem("token");

const instance = axios.create({
  baseURL: urlServer + 'basket/'
  //baseURL:'http://localhost:5000/premex/basket/'
})
//instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export default class BasketService {

    static async editBasket(token, basket) {
        instance.defaults.headers.common['Authorization'] = token;
        const result = await instance.post('edit', { basket });
        return result.data
    }
    static async orderSend(token, {order, basket}) {
        
        instance.defaults.headers.common['Authorization'] = token;
        const result = await instance.post('order', {  order, basket });
        return result.data
    }
    
}

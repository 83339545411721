import React, { useState } from "react";
import { useDispatch } from "react-redux";
import s from "./futter.module.css";
import MyButton from "../UI/button/MyButton";
import { fetchMessage } from "../../redux/slices/chatSlice";


export default function Connect(props) {
    const [formInput, setFormInput] = useState(['', ''])
    const dispatch = useDispatch();
 const submit = () => {
    if (formInput[0].length > 0 & formInput[1].length > 0) {
       dispatch(fetchMessage(formInput)) 
    }
    
    setFormInput(['', ''])
 }

    return (
        <div className={props.classN}>
            <p style={{color: "white"}} className={s.futterQvest}>Подробно ответим на ваши вопросы</p>
            <form className={s.futterRight}>
                <input type="email" name="input" value={formInput[0]} onChange={(e) => {
                    e.preventDefault();
                    setFormInput([e.target.value, formInput[1]])
                }} placeholder="Ваш электронный адрес" />
                <textarea
                    type="text"
                    name="textarea"
                    value={formInput[1]} onChange={(e) => {
                        e.preventDefault();
                        setFormInput([formInput[0], e.target.value ])
                    }}
                    placeholder="Введите ваш текст"
                ></textarea>
                
            </form>
            <MyButton className={s.futterBtn} onClick={submit} >Отправить</MyButton>

        </div>)

}

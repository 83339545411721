import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Futter from "../futter/Futter";
import s from "./documents.module.css";
import { selectDocuments } from "../../redux/selectors";
import { downFile, getFiles } from "../../redux/slices/catalogSlice";


export default function DocumentsM() { 
  const dispatch = useDispatch();
  const documents = useSelector(selectDocuments);

useEffect(() => {
    dispatch(getFiles()) 
}, [dispatch])
  const onClick = (e) => { 
    
    dispatch(downFile({fileName: e.target.id, catalog: 'doc'}))
    
  }
  
  const docs = (docsArray, name) => {return docsArray.map((value, index) => {
    
    return (
      <div key={value + index + 'pr'}    >
        <div className={s.list} >
          <div className={s.col_2}>{value[1]}</div>
          <div className={s.col_3} id={value[0]} onClick={onClick}>PDF</div>
        </div>
  
      </div>
    )
  })}


  return (
    <div className={s.doc}>
      <div className={s.container}>
        <h1>Документация</h1>
        <div className={s.mainList}>
        <div className={s.listBlock}>
           
           {docs(documents.pasport, 'Паспорта')}
        </div>
         
         <div className={s.listBlock}>
           
          {docs(documents.sertificat, 'Сертификаты')}
         </div>
       
         <div className={s.listBlock}>
           
          {docs(documents.declar, 'Декларации')}
         </div>
         <div className={s.listBlock}>
           
          {docs(documents.others, 'Прочие документы')}
         </div>
         
        </div>
      </div>
      <Futter />
    </div>
  );
}

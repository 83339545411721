import React from "react";
import { useForm } from "react-hook-form";
import s from "./formLog.module.css";
import { useDispatch, useSelector } from "react-redux";
import { goRegLog } from "../../../redux/slices/navigateSlice";
import { selectStatus } from "../../../redux/selectors";
import { fetchLog } from "../../../redux/slices/regSlice";

export default function Login() {
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    mode: "onBlur",
  });
  const reg = () => {
    dispatch(goRegLog({ registr: true, login: false }));
  };
  const onSubmit = (data) => {
    reset();

    if (status !== "loading") {
      dispatch(
        fetchLog({
          email: data.email,
          password: data.password,
        })
      );
    }
  };
  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
      

      <div className={s.mailForm}>
        <label className={s.email}>
          E-mail &#8432;
          <input
            className={s.mail}
            {...register("email", {
              // validate
             
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "Не корректный e-mail" ,
                 required: "Поле обязательно к заполнению",
              },
            })}
            placeholder="Введите ваш E-mail"

          />
        </label>
         <div >{errors.email && <p>{errors.email.message || "Ошибка"}</p>}</div>
      </div>
     
      <div className={s.passvordForm}>
        <label className={s.passvord}>
          Пароль &#8432;
          <input
            className={s.mail}
            {...register("password", {
              // validate
           
              maxLength: {
                value: 12,
                message: "Не более 12 символов",
                   required: "Поле обязательно к заполнению",
              },
              minLength: {
                value: 4,
                message: "Не менее 4 символов",
              },
            })}
            type="password"
            autoComplete="on"
            placeholder="Введите ваш пароль"
            
          />
        </label>
           {errors.password && <p>{errors.password.message || "Ошибка"}</p>}
      </div>
     

      <input className={s.formLogBtn} style={{backgroundColor:"#FD3737"}} value="Войти" type="submit" />
      <div className={s.blockReg}>
        <span className={s.helloReg}>Впервый раз здесь?</span>
        <div className={s.helloReg} onClick={reg}>
          Зарегестрируйтесь
        </div>
      </div>
    </form>
  );
}

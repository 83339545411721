import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentCatalog,
  selectActSubCat,
  selectScroll,
  selectUndCat,
} from "../../../redux/selectors";
import {
  catalogActReducer,
  subCatalAct,
} from "../../../redux/slices/catalogSlice";
import s from "./catalog.module.css";
import { goScroll } from "../../../redux/slices/navigateSlice";

export default function UnderCatalog() {

  
  const underCatalog = useSelector(selectUndCat);
  const activCat = useSelector(selectCurrentCatalog);
  const actSubCat = useSelector(selectActSubCat);
  const dispatch = useDispatch();
  const scroll = useSelector(selectScroll);
  
  const onClick = (index) => {
    dispatch(goScroll({ ...scroll, catalog: true }));
    dispatch(subCatalAct([true]));
    dispatch(catalogActReducer([activCat[0], index, 0]))
  };

  if(activCat[0] !==  null) {
    const undCatalog = underCatalog[activCat[0]].map((value, index) => {
    return (
      <div key={"und" + index} className={s.catalogBlockMedium}>
        <span
          id={index}
          onClick={
            !actSubCat[0]
              ? () => onClick(index)
              : () => {
                  return;
                }
          }
          className={s.catalogBlockMediumText}
        >
          {value.name}
        </span>
      </div>
    );
  });
  

  

  return <div className={s.undercatalog}>{undCatalog}</div>;}
}

import axios from "axios";
import { urlServer } from "../config";
//let AUTH_TOKEN =  localStorage.getItem("token");

const instance = axios.create({
  baseURL: urlServer + 'chat/'
  //baseURL:'http://localhost:5000/premex/chat/'
})
//instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export default class ChatService {

    static async sendMessage(message) {
        
        const result = await instance.post('message',  {message} );
        return result.data
    }
  
    
}

import React, { useEffect } from "react";
import s from "./technologyCardLora.module.css";
import Futter from "../futter/Futter";
import MT from "../../image/MT.png";
import WPD from "../../image/WPD.png";
import WPV from "../../image/WPV.png";
import PulsarU from "../../image/PulsarU.png";
import PulsarU_F from "../../image/PulsarU_F.png";
import NBIoT from "../../image/5.png";
import { useDispatch, useSelector } from "react-redux";
import Mult from "./Mult";
import { useNavigate } from "react-router-dom";
import { catalogActReducer, changeCurrProd } from "../../redux/slices/catalogSlice";
import { selectStart } from "../../redux/selectors";
import { startMult } from "../../redux/slices/navigateSlice";
import { HiOutlineChevronRight } from "react-icons/hi2";

const TechnologyCardNBIot = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const start = useSelector(selectStart)

  const prod = [['Счетчик холодной воды крыльчатый МТ', 'МТ DN15',
  MT, [0, 1, 0]],
  ['Счетчик холодной воды турбинный WP-D', 'WP-D DN50',
  WPD, [0, 1, 1]],
  ['Счетчик холодной воды турбинный сопряженный WP-V', 'WP-V DN50/DN15',
  WPV, [0, 1, 2]],
  ['Счетчик воды ультразвуковой Пульсар У DN15', 'Пульсар У DN15',
  PulsarU, [0, 1, 3]],
  ['Счетчик воды ультразвуковой Пульсар У DN25', 'Пульсар У DN25',
  PulsarU, [0, 1, 3]],
  ['Счетчик воды ультразвуковой Пульсар У DN50', 'Пульсар У DN50',
  PulsarU_F, [0, 1, 3]],
  ['Счетчик импульсов Вега', 'Счётчик импульсов Вега NB-11',
  NBIoT, [5, 0, 0]],


  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    setTimeout(() => {
      if (start[2]) {
        let a = [...start]
        a.splice(2, 1, false)
        dispatch(startMult(a))
      }
    }, 7000)
  }, [dispatch, start]);
  const onClick = (catalog, product) => {

    dispatch(catalogActReducer(catalog))
    dispatch(changeCurrProd(product))
    navigate('/product')
  }
  const products = prod.map((el, i) => {
    return (
      <div onClick={() => {
        onClick(el[3], el[1])
      }} key={el[1]} className={s.blockCatalog}>
        <p className={s.numberPoductCatalog}>{'0' + (i + 1)}</p>
        <p className={s.poductCatalog}>
          {el[0]}
        </p>
        <img className={s.svkh} src={el[2]} alt="" />
      </div>
    )
  })

  return (
    <div>
      {(start[1] && !start[1]) ? <Mult close={() => {       // убрать !start[1]
        start.splice(2, 1, false);
        dispatch(startMult(start))
      }} name={'picStartNBiot'} /> :
        <div className={s.technologyCard}>
          <div className={s.container}>
            <div className={s.blockTree} >
              <span onClick={() => navigate(-1)} className={s.item}>
                Технологии
              </span>
              <HiOutlineChevronRight />
              <span className={s.item} >
                NB-IoT
              </span>
            </div>

            <h2>NBiot - <span className={s.text11}>удаленное</span> считывание показаний счетчиков </h2>
            <div className={s.blockTop}>
              <div className={s.blockTopText}>

              </div>
              <div className={s.blockTop}>
                <Mult name={'picNBiot'} />
              </div>

            </div>

            <h3 className={s.hArhitect}>Архитектура сети NB-IoT</h3>
            <p className={s.textArhitect}>Сбор и передача данных с помощью технологии NB-IoT включает следующие элементы:</p>
            <div className={s.blockArhitect}>

              <div className={s.listArhitect}>
                <p className={s.textLeft}>Конечные устройства</p>
                <p className={s.textRight}>Счетчики воды со встроенными или съемными датчиками импульсов.  Датчики собирают данные и затем передают их счетчику импульсов.</p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Шлюз</p>
                <p className={s.textRight}>Прибор, который используются для подсчета количества импульсов, выдаваемых конечным устройством и передающий пакетные данные передаются по протоколу UDP по отдельному, независимому каналу передачи данных В устройство NB-IoT вставляется SIM-карта. Устройство имеет режим Power Save Mode (режим энергосбережения), в котором оно работает с определенными интервалами. Оборудование передает пакеты на платформу в заданное время и уходит в спящий режим, что позволяет экономить заряд батареи.</p>

              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Сетевой сервер </p>
                <p className={s.textRight}>Это компонент сетевой инфраструктуры, который обрабатывает и хранит данные, передаваемые конечными устройствами и шлюзами LoRa. Сервер может быть размещен в облаке или в локальной сети, и обычно на нем работает специализированное программное обеспечение, которое позволяет ему получать, декодировать и обрабатывать данные, передаваемые по сети LoRa.</p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Сервер приложений</p>
                <p className={s.textRight}>Сервер приложений заимодействует с конечными устройствами, отслеживает их состояние и контролирует работу. Является конечной точкой сбора данных. </p>
              </div>
            </div>
            <h3 className={s.hTehnology}>Предлагаемые компоненты системы NB-Iot:</h3>
            <div className={s.listCatalog}>
              {products}

              <p className={s.textTarif}> * Для организации сбора данных с приборов учета с дистанционным съемом ООО "ПремексБел" рекомендует приобрести: SIM-карту мобильного оператора МТС с тарифным планом "Интернет вещей" либо SIM-карту мобильного оператора А1 с тарифным планом "Телеметрия NBiot"</p>
              <p className={s.textSlogan}>Свяжитесь с нами для получения предложения или информации!</p>
            </div>
          </div>

        </div>}
      {!start[2] && <Futter />}
    </div>
  );
};

export default TechnologyCardNBIot;

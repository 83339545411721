import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import s from "./order.module.css";
import { HiXMark } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBasket,
  selectOrdShow,
  selectRegUser,
} from "../../redux/selectors";
import {
  changeBasket,
  fetchBasket,
  orderSend,
  orderShowReducer,
} from "../../redux/slices/basketSlice";

const Order = () => {
  const dispatch = useDispatch();
  const orderShow = useSelector(selectOrdShow);
  const regOrder = useSelector(selectRegUser);
  const basket = useSelector(selectBasket);
  const [show, setShow] = useState({ display: "none" });
  const [valueOrder, setValueOrder] = useState(regOrder);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    values: valueOrder,
  });
  
  useEffect(() => {
    if (orderShow) setShow({ display: "block" });
    else setShow({ display: "none" });
  }, [orderShow]);
  useEffect(() => {
    setValueOrder(regOrder);
  }, [regOrder]);

  const close = () => {
    dispatch(orderShowReducer(false));
  };
  const sendOrder = (data) => {
    dispatch(orderSend({ order: data, basket }));
    dispatch(changeBasket([]));
    dispatch(fetchBasket([]));
    close();
  };
  return (
    <form
      style={show}
      className={s.orderForm}
      onSubmit={handleSubmit(sendOrder)}
    >
  

      <div className={s.blockTop}>
        <span className={s.tytleOrder}>Оформление заказа</span>
        <HiXMark className={s.closeOrder} onClick={close}  />
      </div>
      <div className={s.form}>
        <div className={s.nameForm}>
          <label className={s.name}>
            Имя
            <input
              className={s.inputName}
              placeholder="Ваше имя"
              {...register("name", {
                // validate
                required: "Поле обязательно к заполнению",
              })}
            />
          </label>
          <div>
            {errors.name && <p>{errors.name.message || "Ошибка"}</p>}
          </div>
        </div>
        <div className={s.mailForm}>
          <label className={s.email}>
            E-mail &#8432;
            <input
              className={s.inputEmail}
              placeholder="Введите ваш E-mail"
              {...register("email", {
                // validate
                required: "Поле обязательно к заполнению",
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: "Не корректный e-mail",
                },
              })}
            />
          </label>
          <div>
            {errors.email && <p>{errors.email.message || "Ошибка"}</p>}
          </div>
        </div>
        <div className={s.phoneForm}>
          <label className={s.phone}>
            Контактный телефон &#8432;
            <input
              className={s.inputPhone}
              placeholder="Введите ваш телефон"
              {...register("tel", {
                // validate
                required: "Поле обязательно к заполнению",
                minLength: {
                  value: 1,
                  message: "Не корректный телефон",
                },
              })}
            />
          </label>
          <div>{errors.tel && <p>{errors.tel.message || "Ошибка"}</p>}</div>
        </div>

        <div className={s.companyForm}>
          <label className={s.company}>
            Название предприятия &#8432;
            <input
              className={s.inputCompany}
              placeholder="Название компании"
              {...register("company", {
                // validate
                required: "Поле обязательно к заполнению",
              })}
            />
          </label>
          <div>
            {errors.company && <p>{errors.company.message || "Ошибка"}</p>}
          </div>
        </div>

        <div className={s.unpForm}>
          <label className={s.unp}>
            УНП &#8432;
            <input
              className={s.inputUnp}
              placeholder="Введите ваш УНП"
              {...register("unp", {
                // validate
                required: "Поле обязательно к заполнению",
                maxLength: {
                  value: 9,
                  message: "Не корректный УНП",
                },
                minLength: {
                  value: 9,
                  message: "Не корректный УНП",
                },
              })}
            />
          </label>
          <div>{errors.unp && <p>{errors.unp.message || "Ошибка"}</p>}</div>
        </div>
        <div className={s.commentForm}>
          <label className={s.comment}>Комментарий</label>
          <textarea
            className={s.inputComment}
            placeholder="Введите ваш тест"
            {...register("comment")}
          ></textarea>

          <button className={s.formLogBtn}type="submit">        
          Отправить
          </button>
        </div>
      </div>
    </form>
  );
};

export default Order;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./futter.module.css";
import { selectScroll } from "../../redux/selectors";
import { useNavigate } from "react-router-dom";
import { goScroll } from "../../redux/slices/navigateSlice";
import Connect from "./Connect";

export default function Futter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scroll = useSelector(selectScroll);

  const onScroll = (way) => {
    dispatch(goScroll({ ...scroll, ...way }));
  };

  return (
    <div className={s.futter}>
      <div className={s.container}>
        <div className={s.wrapperFutter}>
          <div className={s.futterTop}>
            <div className={s.futterLeft}>
              <p className={s.futterTextTop}>Давайте работать</p>
              <p className={s.futterTextBottom}>вместе</p>
          </div>
       
            <div className={s.futterCenterMenu}>
              <p
                className={s.futterTextListNav}
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
              >
                Главная
              </p>
              <p
                className={s.futterTextListNav}
                onClick={() => {
                  navigate("/");
                  onScroll({ catalog: true });
                }}
              >
                Каталог
              </p>
              <p
                className={s.futterTextListNav}
                onClick={() => {
                  navigate("/");
                  onScroll({ tech: true });
                }}
              >
                Технологии
              </p>
              <p
                className={s.futterTextListNav}
                onClick={() => {
                  navigate("/documents");
                }}
              >
                Документация
              </p>
              <p
                className={s.futterTextListNav}
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Контакты
              </p>
            </div>
            <div className={s.futterCenter}>
              <p className={s.futterTextList}>Офис ООО "ПремексБел":</p>
              <p className={s.futterTextList}>пер. Козлова, 7а, пом. 8</p>
              <p className={s.futterTextList}>220037, г. Минск</p>
              <p className={s.futterTextList}>Республика Беларусь</p>
              <p className={s.futterTextList}></p>
              <p className={s.futterTextList}>тел. +375 17 344-55-24</p>
              <p className={s.futterTextList}>тел. +375 29 374-20-65</p>
              <p className={s.futterTextList}></p>
              <p className={s.futterTextList}>эл. адрес: info@premexbel.by</p>
            </div>

            <Connect classN={s.futterWrapper}/>
          </div>
          <div className={s.futterBottom}>
            <p className={s.futterCopi}>
              &#169; OxSeDu 2023 Все права защищены
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

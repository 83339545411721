import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import s from "./product.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUndCat,
  selectBasket,
  selectCurrentCatalog,
  selectactiveDevice,
  selectText,
  selectCurrProd,
  selectCatalog,
} from "../../../redux/selectors";
import { Link, useNavigate } from "react-router-dom";
import {
  catalogActReducer,
  changeCurrProd,
  downFile,
  getCatalog,
  getOneDevice,
  subCatalAct,
} from "../../../redux/slices/catalogSlice";
import Futter from "../../futter/Futter";
import MyButton from "../../UI/button/MyButton";
import { goScroll } from "../../../redux/slices/navigateSlice";
import {
  addInBasketReduser,
  fetchBasket,
} from "../../../redux/slices/basketSlice";
import { HiOutlineChevronRight } from "react-icons/hi2";

const Product = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const catalogList = useSelector(selectCatalog);
  const catalog = useSelector(selectUndCat);
  const currCat = useSelector(selectCurrentCatalog);
  const currProd = useSelector(selectCurrProd);
  const basket = useSelector(selectBasket);
  const device = useSelector(selectactiveDevice);
  const textArray = useSelector(selectText);
  const [actPictures, setPic] = useState(0);
  const [disable, setDisable] = useState(false);
  const [products, setProducts] = useState([]);
  const [tree, setTree] = useState([
    "Счетчики воды",
    "Индивидуального учета",
    "Механические",
  ]);
  const imgBox = ["", "", ""];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOneDevice(currProd));
  }, [currProd, dispatch]);

  useEffect(() => {
    dispatch(getCatalog());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setDisable(basket.map((v) => v[0]).includes(device.fullname));
  }, [device.fullname, basket]);

  useEffect(() => {



    if (catalog.length > 0) {

      if (currCat[0] === null) {
        setProducts(catalog[0][currCat[1]].subCatalog[currCat[2]]);
      } else {
        setProducts(catalog[currCat[0]][currCat[1]].subCatalog[currCat[2]]);
        let a = catalog[currCat[0]][currCat[1]].name;
        let b = catalog[currCat[0]][currCat[1]].subCatalog[currCat[2]][0];

        if (a && b) {
          setTree([catalogList[currCat[0]], a, b])
        }
        if (!a && !b) {
          setTree([catalogList[currCat[0]]])
        }
        if (a && !b) {
          setTree([catalogList[currCat[0]], a])
        }
        if (!a && b) {
          setTree([catalogList[currCat[0]], b])
        }
      }
    }
  }, [catalog, currCat, catalogList, dispatch]);


  const goBack = (e) => {
    if (e.target.id === "0") {

      dispatch(catalogActReducer([currCat[0], currCat[1], 0]));
      dispatch(subCatalAct([false]));
    }

    if (e.target.id === "1") {
      dispatch(catalogActReducer([currCat[0], currCat[1], 0]));
    }
    dispatch(goScroll({ catalog: true, tech: false }));
    setPic(0);
    
    navigate("/");
  };
  const addProdtoBasket = () => {
    dispatch(addInBasketReduser([device.fullname, 1]));

    dispatch(fetchBasket([...basket, [device.fullname, 1]]));
  };
  const downloadF = (fileName, catalog) => {
    dispatch(downFile({ fileName, catalog }));
  };
  const bacKCatalog = () => {
    return (
      <div className={s.blockTree}>
        <span
          className={s.item}
          onClick={() => {
            navigate("/catalog");
          }}
        >
          Каталог
        </span>
      </div>
    );
  };

  let treePath = tree.map((value, index) => {
    return (
      <div className={s.blockTree} key={value}>
        {tree[index + 1] || tree.length === 1 ? (
          <>
            <span id={index} onClick={goBack} className={s.item}>
              {value}
            </span>
            <HiOutlineChevronRight />
          </>
        ) : (
          <>
            <span className={s.item} style={{ fontStyle: "italic" }}>
              {value}
            </span>
          </>
        )}
      </div>
    );
  });

  const images = imgBox.map((value, index) => {
    return (
      <div
        key={"img" + index}
        className={s.picItem}
        style={
          device.img[index]
            ? { border: "1px solid #e7eaef" }
            : { border: "1px  #e7eaef" }
        }
      >
        <img
          id={index}
          onClick={(e) => {
            setPic(Number(e.target.id));
          }}
          className={s.picMini}
          src={
            "https://premexbel.by/premex/static/" + device.img[index] + ".jpg"
          }
          alt=""
        />
      </div>
    );
  });

  const prodBut = products.map((value, index) => {
    if (index === 0) return <div key={index + "pr "}></div>;
    if (value === currProd) {
      return (
        <div key={index + "pr "} className={s.productListBlock}>
          <div
            className={s.productList}
            style={{ background: "#e7eaef", color: "#2c2c2c" }}
          >
            {value}
          </div>
          {!disable ? (
            <MyButton onClick={addProdtoBasket} className={s.order}>
              В корзину
            </MyButton>
          ) : (
            <MyButton className={s.orderDis}>В корзине</MyButton>
          )}
        </div>
      );
    }
    return (
      <div
        onClick={() => {
          setPic(0);
          dispatch(changeCurrProd(value));
        }}
        className={s.productList}
        key={index + "pr "}
      >
        {value}
      </div>
    );
  });

  const descr = device.techcharact.map((value, index) => {
    return (
      <div key={index + "pro "}>
        <div className={s.tech}>
          <p>{value[0]}</p>
          <p className={s.techLast}>{value[1]}</p>
        </div>
        <div className={s.line}></div>
      </div>
    );
  });
  const gar = device.guarant.map((value, index) => {
    return (
      <div key={index + "prod "}>
        <div className={s.tech}>
          <p>{value[0]}</p>
          <p>{value[1]}</p>
        </div>
        <div className={s.line}></div>
      </div>
    );
  });
  const doc = device.documents.map((value, index) => {
    return (
      <div key={index + "produ "}>
        <div className={s.tech}>
          <p>{value[0]}</p>
          <p
            className={s.techPdf}
            onClick={() => {
              downloadF(value[1], device.catalog);
            }}
          >
            PDF
          </p>
        </div>
        <div className={s.line}></div>
      </div>
    );
  });
  const formatText = textArray.map((value, index) => {
    if (index === textArray.length - 1)
      return (
        <Link
          to={device.url}
          style={{ textColor: "red" }}
          key={index + "ddd"}
          className={s.text}
        >
          {value}
        </Link>
      );
    return (
      <span key={index + "ddd"} className={s.text}>
        {value}
      </span>
    );
  });
  let image =
    "https://premexbel.by/premex/static/" + device.img[actPictures] + ".jpg";
  return (
    <AnimatePresence>
      <div>
        <div className={s.product}>
          <div className={s.container}>
            <div className={s.block}>
              <div className={s.blockTop}>
                <div className={s.pic}>
                  <div className={s.list}>
                    {!props.mobile ? treePath : bacKCatalog()}
                  </div>
                  <div className={s.picBlock}>
                    <div className={s.picItems}>{images}</div>

                    <div className={s.picTop}>
                      <motion.img
                        className={s.picBig}
                        key={image.src}
                        src={image}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        alt=""
                      />
                    </div>
                  </div>
                  <span className={s.textLogo}>
                    {" "}
                    Производитель {device.producer}
                  </span>
                </div>
                <div className={s.title}>
                  <div className={s.blockTitle}>
                    <h2>{device.fullname}</h2>
                  </div>
                  <div className={s.productCelect}>
                    <div className={s.celect}>{prodBut}</div>
                  </div>
                </div>
              </div>
              <div className={s.blockBottom}>
                <div className={s.blockBottomLeft}>
                  {formatText}
                  {/*<div className={s.picLine}>
                  <img src={picline} alt="" />
                  </div>*/}
                </div>
                <div className={s.blockBottomRight}>
                  <div className={s.description}>
                    <h3>Технические характеристики</h3>

                    <div className={s.descrTech}>{descr}</div>
                  </div>
                  <div className={s.garant}>
                    <h3>Гарантия</h3>
                    <div className={s.descripGarant}>{gar}</div>
                  </div>
                  <div className={s.document}>
                    <h3>Документация</h3>
                    <div className={s.doc}>{doc}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Futter />
      </div>
    </AnimatePresence>
  );
};

export default Product;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./formLog.module.css";
import { HiXMark } from "react-icons/hi2";
import {
  registrReducer,
  registrError,
  registrChangeStatus,
} from "../../../redux/slices/regSlice";
import {
  selectError,
  selectStatus,
} from "../../../redux/selectors";
import Login from "./Login";

const Log = ({ style, close }) => {


  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  useEffect(() => {

    if (status === 'ok') {
      close();
      dispatch(registrChangeStatus(null))
    }



  }, [status, close, dispatch])



  const errom = () => {
    dispatch(registrReducer({ email: "", role: "" }));
    return dispatch(registrError({ error: null }));
  };
  return (

    <div className={s.popReg}>
      <div style={style} className={s.logForm}>

        <HiXMark className={s.close} onClick={close} />
        <div className={s.blockTop}>
          <span className={s.hello}>Добро пожаловать</span>
        </div>


        {error && (
          <>
            <div className={s.errorLogin}>
              <div className={s.errorText}>{error}</div>
              <HiXMark className={s.errorClose} onClick={errom} cursor={'pointer'} size={25} color={'#FD3737'} margin={'17px'} />

            </div>
          </>
        )}

        <Login />

      </div>
    </div>



  );
};
export default Log;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BasketService from '../../api/basketService';


export const fetchBasket = createAsyncThunk(
    'basket/fetchBasket',
    async (basket, { rejectWithValue, dispatch, getState }) => {
        let state = getState();
         
        const token = localStorage.getItem("tokenPremex");

        if (state.regReducer.auth) {
            try {
                await BasketService.editBasket(token, basket);

            } catch (error) {
                return rejectWithValue(error.response.data.message);
            }
        }


    }
)
export const orderSend = createAsyncThunk(
    'basket/orderSend',
    async (order, { rejectWithValue, dispatch, getState }) => {
        
         
        const token = localStorage.getItem("tokenPremex");

            try {

                await BasketService.orderSend(token, order);

            } catch (error) {
                return rejectWithValue(error.response.data.message);
            }
        

    }
)

const setError = (state, action) => { //обработка ошибки
    state.status = 'rejected';
    state.error = action.payload;
}

const basket = createSlice({
    name: 'basket',
    initialState: {
        basket: [],
        basketShow: false,
        orderShow: false,
        status: null,
        error: null,
    },
    reducers: {
        basketShowReducer(state, action) {

            return {
                ...state, basketShow: action.payload
            }
        },
        orderShowReducer(state, action) {

            return {
                ...state, orderShow: action.payload
            }
        },


        addInBasketReduser(state, action) {

            return {
                ...state, basket: [...state.basket, [...action.payload]]
            }
        },

        changeBasket(state, action) {

            return {
                ...state, basket: [...action.payload]
            }
        },


        registrError(state, action) {
            return {
                ...state, error: action.payload.error
            }
        }
    },
    extraReducers: {
        [fetchBasket.pending]: (state, action) => {
            state.status = 'loading';

        }, // метод жизненного цикла - загрузка 
        [fetchBasket.fulfilled]: (state, action) => {
            state.status = null;
        }, // метод жизненного цикла - успешное получение данных
        [fetchBasket.rejected]: setError,
        // метод жизненного цикла - неполадка



    }
})

export const { basketShowReducer, orderShowReducer, addInBasketReduser, changeBasket, registrError, extraReducers } = basket.actions
export default basket.reducer
import regReducer from './slices/regSlice';
import catalog from './slices/catalogSlice';
import navigate from './slices/navigateSlice';
import basket from './slices/basketSlice';
import chat from './slices/chatSlice';
import { configureStore } from '@reduxjs/toolkit'


export const store = configureStore({
    reducer: {
        regReducer,
        catalog,
        navigate,
        basket,
        chat,
    }
  })

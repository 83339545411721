import React from "react";
import classes from "./myButton.module.css";
function MyButton(props) {
 
  return (
    <button className={classes.logout}{...props}>
      {props.children}
    </button>
  );
}

export default MyButton;

import React, { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import s from "./technology.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectScroll } from "../../redux/selectors";
import { goScroll } from "../../redux/slices/navigateSlice";

import { useNavigate } from "react-router-dom";

const Technologes = () => {
  const scroll = useSelector(selectScroll);
  const refTech = useRef(null);
 
  const dispatch = useDispatch();
  
 

  const navigator = useNavigate();
  const loraDiscr = [
    "Беспроводная технология передачи небольших по объёму данных на дальние расстояния, разработанная для распределённых сетей телеметрии, межмашинного взаимодействия и интернета вещей. LPWAN является одной из беспроводных технологий, обеспечивающих среду сбора данных с различного оборудования: датчиков, счётчиков ЖКХ, устройств охранно-пожарной сигнализации и т.п... ",
    //"Устройство или модем с LPWAN-модулем передает данные по радиоканалу на базовую станцию. Станция принимает сигналы от всех устройств в радиусе своего действия, оцифровывает и передаёт на удалённый сервер, используя доступный канал связи: Ethernet, сотовая связь, VSAT. Полученные на сервере данные используются для отображения, анализа, построения отчетов и принятия решений",
  ];
  const IoTDiscr = [
    "IoT (Интернет вещей)  — это сеть физических устройств, машин и других объектов, которые используют датчики и программное обеспечение для сбора данных и обмена ими через Интернет, что позволяет осуществлять удаленный мониторинг и управление...",
  ];
  const NbiotDiscr = [
    "Это технология, которая позволяет большому количеству устройств отправлять данные там, где нет стандартного покрытия мобильной сети. Он использует лицензированный частотный спектр, в котором нет помех другим устройствам, что гарантирует более надежную передачу данных. NB-IoT — это LPWAN (глобальные сети с низким энергопотреблением) — сеть, которая может покрывать большие территории, используя при этом меньшее количество энергии...",
  ];
  const RSDiscr = [
    "RS-485 (также известный как TIA/EIA-485) — это стандартный интерфейс для физической связи. RS-485 позволяет нескольким устройствам (до 32) обмениваться данными в полудуплексном режиме по одной паре проводов плюс заземляющий провод на расстояниях до 1200 метров...",
  ];
  const MBusDiscr = [
    "M-Bus  — это технология проводной связи, используемая для удаленного считывания показаний, например, счетчиков электроэнергии, воды, тепла и температуры. Для передачи данных через M-Bus требуется так называемое ведущее устройство (преобразователь уровня или регистратор данных M-Bus). Подключенные к нему измерительные устройства, передают свои данные по двухпроводной линии, где они собираются, сохраняются и обрабатываются... ",
  ];

  useEffect(() => {
    if (scroll.tech) {
      refTech.current.scrollIntoView({ behavior: "smooth" });
      dispatch(goScroll({ ...scroll, tech: false }));
    }
  }, [scroll, dispatch]);

  const MiniCardsTech = ({className, item}) => {
    const ref = useRef(null);

    const isInView = useInView(ref, {
      margin: "0px 100px -55% 0px",
       });
    
    return (
      <div
        ref={ref}
        style={{
          opacity: isInView ? 1 : 0.2,
          transition: "all 0.5s ",
        }}
        onClick={()=>{navigator("/technology/"+item[1])}}
        className={className}
      >
        <div className={s.technologyBlockCenter}>
          <div className={s.blockNum}>
            <p className={s.technologyNumber} id={1}>
            {item[0]}
          </p>
          <h3 id={1}>{item[1]}</h3>
          </div>
          
          <div className={s.line}></div>
         
            <p className={s.technologyText2} id={3}></p>
            <p className={s.technologyText} id={1}>
            {item[2]}
          </p>
          
        </div>
      </div>
    );
  };

  return (
    <div>
      <div ref={refTech} className={s.technology}>
        <div className={s.container}>
          <div className={s.technologyBlock}>
            <div>
            <h2  style={{position:"sticky", top: "20px"}} className={s.technologyTitle}>Технологии</h2>
            </div>
            
            <div className={s.technologyCards}>
              <MiniCardsTech  className={s.cardLora}
                item={["01.", "Lora", loraDiscr]}
              ></MiniCardsTech>
              <MiniCardsTech className={s.technologyCard}
                item={["02.", "IoT", IoTDiscr]}
              ></MiniCardsTech>
              <MiniCardsTech className={s.technologyCard}
                item={[
                  "03.", "NB-Iot", NbiotDiscr,
                ]}
              ></MiniCardsTech>
              <MiniCardsTech className={s.technologyCard}
                item={[
                  "04.",
                  "RS-485",
                  RSDiscr,
                ]}
              ></MiniCardsTech>
              <MiniCardsTech className={s.technologyCard}
                item={["05.", "M-Bus", MBusDiscr]}
              ></MiniCardsTech>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technologes;

import React from 'react'
import './mult.css'

const Mult = ({name, close}) => {
  
  return (
    <div onClick={close} className='picWrapper'>

      <div className={name}>

    </div>
    </div>
    
  )
}
export default Mult
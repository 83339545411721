import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CatalogService from '../../api/catalogService';
import fileDownload from 'js-file-download';



export const getCatalog = createAsyncThunk(
    'getCatalog/fetchCatalog',
    async (_, { rejectWithValue, dispatch, getState }) => {


        try {
            const response = await CatalogService.getCatalog();

            return response

        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }

    }
)

export const getOneDevice = createAsyncThunk(
    'getOneDevice/fetchCatalog',
    async (name, { rejectWithValue, dispatch, getState }) => {


        try {
            const response = await CatalogService.getDevice(name);

            return response

        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }

    }
)

export const downFile = createAsyncThunk(
    'downFile/fetchCatalog',
    async ({ fileName, catalog }, { rejectWithValue, dispatch, getState }) => {


        try {
            const file = await CatalogService.uploadFile(fileName, catalog);

            fileDownload(file.data, fileName + '.pdf')

        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }

    }
)
export const getFiles = createAsyncThunk(
    'getFiles/fetchCatalog',
    async (_, { rejectWithValue, dispatch, getState }) => {

        try {
            const files = await CatalogService.getFiles();

            return files

        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }

    }
)

const setError = (state, action) => { //обработка ошибки
    state.status = 'rejected';
    state.error = action.payload;
}

const catalog = createSlice({
    name: 'catalog',
    initialState: {



        catalog: ["Счетчики воды", "Счетчики тепла", "Электросчетчики",
            "Газовые анализаторы", "Передача и хранение данных приборов учета", "Дополнительное оборудование",
            "Услуги по подключению"],
        underCatalog: [],




        activeDevice: {
            name: '',
            catalog: '',
            undercatalog: '',
            subcatalog: '',
            techcharact: [],
            guarant: [],
            text: "Счетчики холодной воды турбинные MT предназначены для измерения объема питьевой воды, технической воды, протекающей в системах холодного водоснабжения, а также в трубопроводах открытых и закрытых систем теплоснабжения при давлении до 1,6 МПа и диапазоне температур от +0,1 до +30 С.; Для дистнационной передачи данных счетчик оснащен защищенным от воды счетным механизмом с возможностью присоединения импульсного датчика герконного типа. Конструкцией предусмотрена защита магнитной муфты и датчика импульсов от воздействия внешнего магнитного поля напряженностью 100КА/м.; Счетчики внесены в Государственный реестр средств измерений.; Регистрационный номер № РБ 03 07 6770 20. ",
            documents: [],
            img: []
        },
        documents: {
            pasport: [],
            sertificat: [],
            declar: [],
            others: []
        },
        currentCatalog: [null, 0, 0],
        subCatStart: [false],
        currentProduct: '',
        getDevice: [null, null, null, null], //?
        detail: {},
        auth: false,
        status: null,
        error: null,
    },
    reducers: {

        catalogActReducer(state, action) {

            return {
                ...state, currentCatalog: [...action.payload]
            }
        },


        subCatalAct(state, action) {

            return {
                ...state, subCatStart: [...action.payload]
            }
        },

        changeCurrProd(state, action) {

            return {
                ...state, currentProduct: action.payload
            }
        },

        registrError(state, action) {
            return {
                ...state, error: action.payload.error
            }
        }
    },
    extraReducers: {


        [getCatalog.pending]: (state, action) => {
            state.status = 'loading';

        }, // метод жизненного цикла - загрузка 
        [getCatalog.fulfilled]: (state, action) => {
            state.status = null
            let catalog = action.payload.map((el) => {
                return el.catalogName
            })
            let underCatalog = action.payload.map((el) => {
                return el.underCatalog
            })
            state.catalog = [...catalog]
            state.underCatalog = [...underCatalog]
        }, // метод жизненного цикла - успешное получение данных
        [getCatalog.rejected]: setError,
        // метод жизненного цикла - неполадка



        [getOneDevice.pending]: (state, action) => {
            state.status = 'loading';

        }, // метод жизненного цикла - загрузка 
        [getOneDevice.fulfilled]: (state, action) => {
            state.status = null;
            state.activeDevice = { ...action.payload }

        }, // метод жизненного цикла - успешное получение данных
        [getOneDevice.rejected]: setError,
        // метод жизненного цикла - неполадка

        [getFiles.pending]: (state, action) => {
            state.status = 'loading';

        }, // метод жизненного цикла - загрузка 
        [getFiles.fulfilled]: (state, action) => {
            state.documents = { ...action.payload };
            state.status = null;
        }, // метод жизненного цикла - успешное получение данных
        [getFiles.rejected]: setError,
        // метод жизненного цикла - неполадка

    }
})

export const { catalogActReducer, subCatalAct,
    changeCurrProd, registrError, extraReducers } = catalog.actions
export default catalog.reducer
import React, { useEffect } from "react";
import s from "./technologyCardLora.module.css";
import Futter from "../futter/Futter";
import svkh from "../../image/CBKX_1_LoRa.jpg";
import { useDispatch, useSelector } from "react-redux";
import Mult from "./Mult";
import { useNavigate } from "react-router-dom";
import { catalogActReducer, changeCurrProd } from "../../redux/slices/catalogSlice";
import { selectStart } from "../../redux/selectors";
import { startMult } from "../../redux/slices/navigateSlice";
import { HiOutlineChevronRight } from "react-icons/hi2";

const TechnologyCardMbus = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const start = useSelector(selectStart)

  const prod = [['Теплосчетчик механический «Экохит М» с интерфейсом M-Bus', 'Экохит М M-Bus DN15',
    svkh, [1, 0, 0]],
  ['Теплосчетчик ультразвуковой Экохит У с интерфейсом M-Bus', 'Экохит У M-Bus DN15',
    svkh, [1, 1, 0]],
  ['Конвертер RS-485/USB', 'Конвертер RS-485/ USB Пульсар',
    svkh, [4, 0, 0]],
  ['Источник питания ИП15-60, 3А', 'Источники питания Пульсар',
    svkh, [5, 0, 0]],



  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    setTimeout(() => {
      if (start[4]) {
        let a = [...start]
        a.splice(4, 1, false)
        dispatch(startMult(a))
      }
    }, 7000)
  }, [dispatch, start]);
  const onClick = (catalog, product) => {

    dispatch(catalogActReducer(catalog))
    dispatch(changeCurrProd(product))
    navigate('/product')
  }
  const products = prod.map((el, i) => {
    return (
      <div onClick={() => {
        onClick(el[3], el[1])
      }} key={el[1]} className={s.blockCatalog}>
        <p className={s.numberPoductCatalog}>{'0' + (i + 1)}</p>
        <p className={s.poductCatalog}>
          {el[0]}
        </p>
        <img className={s.svkh} src={el[2]} alt="" />
      </div>
    )
  })
  return (
    <div>
      {(start[1] && !start[1]) ? <Mult close={() => {       // убрать !start[1]
        start.splice(4, 1, false);
        dispatch(startMult(start))
      }} name={'picStartMBus'} /> :
        <div className={s.technologyCard}>
          <div className={s.container}>
            <div className={s.blockTree} >
              <span onClick={() => navigate(-1)}  className={s.item}>
                Технологии
              </span>
              <HiOutlineChevronRight />
              <span className={s.item} >
                M-Bus
              </span>
            </div>

            <h2>M-Bus — надежная кабельная сеть </h2>

            <div className={s.blockTop}>

              <div className={s.blockTop}>
                <Mult name={'picMBus'} />
              </div>

            </div>

            <h3 className={s.hArhitect}>Архитектура сети M-Bus</h3>
            <p className={s.textArhitect}>Сбор и передача данных с помощью технологии M-Bus включает следующие элементы:</p>
            <div className={s.blockArhitect}>

              <div className={s.listArhitect}>
                <p className={s.textLeft}>Конечные устройства</p>
                <p className={s.textRight}>Счетчики энергоресурсов (счетчики воды, счетчики тепла), которые собирют данные из своей среды. </p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Ведущее устройство</p>
                <p className={s.textRight}>Ведущее устройство отправляет запросы и получает данные от ведомых устройств.  По запросу счетчики через определенные промежутки времени передают собранные данные общему ведущему устройству. Он может считывать показания всех счетчиков коммунальных услуг в здании. Сигналы передаются последовательно от подключенных ведомых устройств к ведущему по двухпроводной линии обратной полярности.</p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Сетевой сервер </p>
                <p className={s.textRight}>Это компонент сетевой инфраструктуры, который обрабатывает и хранит данные, передаваемые ведущим устройством. Сервер может быть размещен в облаке или в локальной сети, и обычно на нем работает специализированное программное обеспечение, которое позволяет ему получать, декодировать и обрабатывать данные.</p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Сервер приложений</p>
                <p className={s.textRight}>Сервер приложений заимодействует с конечными устройствами, отслеживает их состояние и контролирует работу. Является конечной точкой сбора данных.</p>
              </div>
            </div>
            <h3 className={s.hTehnology}>Предлагаемые компоненты M-Bus системы:</h3>
            <div className={s.listCatalog}>
              {products}

            </div>

            <p className={s.textTarif}> * Для организации сбора данных с приборов учета с дистанционным съемом ООО "ПремексБел" рекомендует приобрести: SIM-карту мобильного оператора МТС с тарифным планом "Интернет вещей" либо SIM-карту мобильного оператора А1 с тарифным планом "Телеметрия NBiot"</p>
            <p className={s.textSlogan}>Свяжитесь с нами для получения предложения или информации!</p>
          </div>
        </div>}
        {!start[2] && <Futter />}
    </div>

  );
};

export default TechnologyCardMbus;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import RegLogService from '../../api/reglogService';
import { changeBasket } from './basketSlice';

export const fetchReg = createAsyncThunk(
    'regist/fetchReg',
    async ({ email, password, tel, company, unp, role }, { rejectWithValue, dispatch, getState }) => {
        //console.log(getState());
        try {
            const response = await RegLogService.regUser({ email, password, tel, company, unp, role });
            localStorage.setItem("tokenPremex", response.token)
            dispatch(registrReducer({ email: response.email, role: response.role, tel: response.tel, company: response.company, unp: response.unp }));
            dispatch(registrAuth(true))
        } catch (error) {
            dispatch(registrAuth(false))
            return rejectWithValue(error.response.data.message);
        }

    }
)
export const authCheck = createAsyncThunk(
    'regist/authCheck',
    async (_, { rejectWithValue, dispatch, getState }) => {
        //console.log(getState());
        const token = localStorage.getItem("tokenPremex");

        try {
            const response = await RegLogService.authCheck(token);

            dispatch(registrReducer({
                email: response.user.email, role: response.user.role, tel: response.user.tel,
                company: response.user.company, unp: response.user.unp
            }));
            dispatch(registrAuth(true))
            dispatch(changeBasket(response.basket.products))

        } catch (error) {
            dispatch(registrAuth(false))
            return rejectWithValue(error.response.data.message);
        }

    }
)
export const fetchLog = createAsyncThunk(
    'regist/fetchLog',
    async ({ email, password }, { rejectWithValue, dispatch, getState }) => {
        //console.log(getState());
        try {
            const response = await RegLogService.logUser({ email, password });
          
            localStorage.setItem("tokenPremex", response.user.token)
            dispatch(registrReducer({
                email: response.user.email, role: response.user.role,
                tel: response.user.tel, company: response.user.company, unp: response.user.unp
            }));
            dispatch(registrAuth(true))
            
            dispatch(changeBasket(response.basket.products));
        } catch (error) {
            dispatch(registrAuth(false))
            return rejectWithValue(error.response.data.message);
        }

    }
)
const setError = (state, action) => { //обработка ошибки
    
    state.status = 'rejected';
    state.error = action.payload;
}

const regSlice = createSlice({
    name: 'regist',
    initialState: {
        reg: {
            email: '',
            role: '',
            tel: '',
            company: '',
            unp: '',
            name: '',
            mess: ''

        },
        auth: false,
        status: null,
        error: null
    },
    reducers: {
        registrReducer(state, action) {
            return {
                ...state, reg: action.payload

            }

        },
        registrAuth(state, action) {
            
            return {
                ...state, auth: action.payload

            }

        },
        registrChangeStatus(state, action) {
            return {
                ...state, status: action.payload

            }

        },
        registrError(state, action) {
            return {
                ...state, error: action.payload.error
            }
        }
    },
    extraReducers: {
        [fetchReg.pending]: (state, action) => {
            state.status = 'loading';
            

        }, // метод жизненного цикла - загрузка 
        [fetchReg.fulfilled]: (state, action) => {
            state.status = 'ok';  
        }, // метод жизненного цикла - успешное получение данных
        [fetchReg.rejected]: setError,
        // метод жизненного цикла - неполадка

        [authCheck.pending]: (state, action) => {
            state.status = 'loading';

        }, // метод жизненного цикла - загрузка 
        [authCheck.fulfilled]: (state, action) => {
            state.status = null;
        }, // метод жизненного цикла - успешное получение данных
        [authCheck.rejected]: (state, action) => {
            state.status = null;
        },
        // метод жизненного цикла - неполадка
        [fetchLog.pending]: (state, action) => {
            state.status = 'loading';
            

        }, // метод жизненного цикла - загрузка 
        [fetchLog.fulfilled]: (state, action) => {
            state.status = 'ok';  
           
        }, // метод жизненного цикла - успешное получение данных
        [fetchLog.rejected]: setError,
        // метод жизненного цикла - неполадка


    }
})

export const { registrReducer, registrAuth, registrChangeStatus, registrError, extraReducers } = regSlice.actions
export default regSlice.reducer
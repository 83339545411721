import { createSelector } from "@reduxjs/toolkit";

export const selectRegUser = state => state.regReducer.reg;
export const selectError = state => state.regReducer.error;
export const selectStatus = state => state.regReducer.status;
export const selectRegistr = state => state.form.registr;
export const selectLogin = state => state.form.login;

export const selectAuth = createSelector([selectRegUser, selectError], (selectRegUser, selectError) => {

    if (selectRegUser.role && !selectError) return true
    return false
})

//Каталог

export const selectCatalog = state => state.catalog.catalog;
export const selectUndCat = state => state.catalog.underCatalog;
export const selectCurrentCatalog = state => state.catalog.currentCatalog;
export const selectActSubCat = state => state.catalog.subCatStart;
export const selectactiveDevice = state => state.catalog.activeDevice;
export const selectCurrProd = state => state.catalog.currentProduct;
export const selectProducts = state => state.catalog.products;
export const selectCatStatus = state => state.catalog.status;
export const selectDocuments = state => state.catalog.documents;


/*
export const selectDevice = state => state.catalog.getDevice;
export const selectDevices = state => state.catalog.devices;


*/
export const selectText = createSelector([selectactiveDevice], (device) => {
    if(device.text) 
    {const textArray = device.text.split(';')
    return textArray}
    return []
})

// навигация  скролл, технологии

export const selectScroll = state => state.navigate.scroll;
export const selectMenu = state => state.navigate.menu;
export const selectReg = state => state.navigate.registr;
export const selectLog = state => state.navigate.login;
export const selectStart = state => state.navigate.startTechMult;

// корзина

export const selectBasShow = state => state.basket.basketShow;
export const selectOrdShow = state => state.basket.orderShow;
export const selectBasket = state => state.basket.basket;

export const selectVolBas = createSelector([selectBasket], (basket) => {
    return basket.map(v => (v[1]))
})

//status, error

export const selStatus = createSelector([selectStatus, selectCatStatus], (regStatus, catStatus) => {

    if (regStatus === 'loading' || catStatus === 'loading') return 'loading'
    if (regStatus === 'rejected' || catStatus === 'rejected') return 'rejected'
    return null
})
//admin
export const selectOrders = state => state.admin.orders;


//adminMenu
export const selectFiles = state => state.adminMenu.files;

import React, { useEffect, useState } from "react";
import card from "../../image/card.png";
import profile from "../../image/profil.png";
import { NavLink, useNavigate } from "react-router-dom";
import {
  selectAuth, 
  selectBasket,
  selectRegUser,
  selectScroll,
} from "../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { registrAuth, registrReducer } from "../../redux/slices/regSlice";
import s from "./navBar.module.css";
import { goRegLog, goScroll } from "../../redux/slices/navigateSlice";
import { basketShowReducer, changeBasket } from "../../redux/slices/basketSlice";
import { HiXMark } from "react-icons/hi2";

export default function NavBar() {
  const dispatch = useDispatch();
  let auth = useSelector(selectAuth);
  let { email } = useSelector(selectRegUser);
  const scroll = useSelector(selectScroll);
  const basket = useSelector(selectBasket);
  const [red, setRed] = useState({  });
  const navigate = useNavigate();
  useEffect(() => {
    if (basket.length > 0) {
      setRed({ opacity: 1 });
    } else setRed({ opacity: 0 });
  }, [basket]);

  const onClick = () => {
    dispatch(registrReducer({ email: "", role: "" }));
    dispatch(registrAuth(false))
    dispatch(changeBasket([]));
    localStorage.clear("tokenPremex");
  };
  const onScroll = (way) => {
    dispatch(goScroll({ ...scroll, ...way }));
  };
  const showBasket = () => {
    dispatch(basketShowReducer(true));
  };
 let shortEmail = email.slice(0, email.indexOf('@'));
 if (shortEmail.length > 7 ) {
    shortEmail = email.slice(0, 7)+'...'
 } 
 
  return (
    <div className={s.container}>
      <div className={s.headerTop}>
        <div onClick={() => navigate("/")}
          className={s.logo}>

        </div>
        

        <div className={s.menuBlock}>
          <NavLink className={s.menuBlockItem} to="/about">
            О компании
          </NavLink>

          <div
            onClick={() => {
              navigate("/");
              onScroll({ catalog: true });
            }}
            className={s.menuBlockItem}
          >
            Каталог
          </div>

          <div
            onClick={() => {
              navigate("/");
              onScroll({ tech: true });
            }}
            className={s.menuBlockItem}
          >
            Технологии
          </div>

          <NavLink className={s.menuBlockItem} to="/documents">
            Документация
          </NavLink>

          <NavLink className={s.menuBlockItem} to="/contact">
            Контакты
          </NavLink>
        </div>
          <div className={s.menuEnter}>
          {auth ? (
            <>
            <div className={s.cabinet}>

                <h5>{shortEmail}</h5> 
                <HiXMark className={s.close} onClick={onClick}  />

                       </div>
            
            </>
          ) : (
            <div className={s.regLog}>
              <div
                className={s.enter}
                onClick={() => {
                  navigate("/");
                  dispatch(goRegLog({ registr: false, login: true }));
                }}
              >
                <img className={s.profile} src={profile} alt="" />
              </div>{" "}
            </div>
          )}
          <div className={s.blockCard}>
            <img onClick={showBasket} className={s.card} src={card} alt="" />
            <div style={red} className={s.cardCircle}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import PageMaine from "./components/PageMain";
import Contacts from "./components/contacts/Contacts";
import Product from "./components/pages/catalog/Product";
import MainPageM from "./components/mobile/MainPageM";
import NavBar from "./components/navbar/NavBar";
import NavBarM from "./components/mobile/navbarM/NavBarM";
import MenuBurger from "./components/mobile/menuBurger/MenuBurger";
import { useSelector } from "react-redux";
import { selStatus, selectMenu, selectBasShow } from "./redux/selectors";
import Basket from "./components/basket/Basket";
import Order from "./components/order/Order";
import Preloder from "./components/Preloder";
import Documents from "./components/documents/Documents";
import GeneralCatalog from "./components/pages/catalog/GeneralCatalog";
import Technologes from "./components/technology/Technology";
import DocumentsM from "./components/documents/DocumentsM";
import TechnologyCardLora from "./components/technology/TechnologyCardLora.jsx";
import TechnologyCardIot from "./components/technology/TechnologyCardIot.jsx";
import TechnologyCardNBIot from "./components/technology/TechnologyCardNBIot.jsx";
import TechnologyCardMbus from "./components/technology/TechnologyCardMbus.jsx";
import TechnologyCardRS from "./components/technology/TechnologyCardRS.jsx";


function App() {
  const status = useSelector(selStatus);
  const menu = useSelector(selectMenu);
  const showBasket = useSelector(selectBasShow);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const resize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', resize)
  }, [])

  return (
    <div className="font-dancing-script">

      {Number(size.width) > 1025 ?
        <>
          {status === "loading" && <Preloder />}
          
          <NavBar />
          <Basket />
          <Order />
          <div style={showBasket ? {opacity: 0.2} : undefined}>
              <Routes>

            <Route path="/*" element={<PageMaine />}></Route>
            <Route path="/contact" element={<Contacts />}></Route>
            <Route path="/product" element={<Product />}></Route>
            <Route path="/technology/loraa" element={<TechnologyCardLora />}></Route>
            <Route path="/technology/iota" element={<TechnologyCardIot />}></Route>
            <Route path="/technology/nb-iota" element={<TechnologyCardNBIot />}></Route>
            <Route path="/technology/rs-485a" element={<TechnologyCardRS />}></Route>
            <Route path="/technology/m-busa" element={<TechnologyCardMbus />}></Route>
            <Route path="/documents" element={<Documents />}></Route>
          </Routes>
          </div>
        
        </>
        :
        <>
          {status === "loading" && <Preloder />}
          <NavBarM />
          <Basket />
          <Order />
          <div className="mobile" style={{ width: "100%", overflow: "hidden" }}>
            {menu && <MenuBurger />}
            <Routes>
              <Route path="/*" element={<MainPageM />}></Route>
              <Route path="/contact" element={<Contacts />}></Route>
              <Route path="/catalog" element={<GeneralCatalog size={Number(size.width)} />}></Route>
              <Route path="/product" element={<Product mobile={'mobile'} />}></Route>
              <Route path="/technologes" element={<Technologes />}></Route>
              <Route path="/technology/loraa" element={<TechnologyCardLora />}></Route>
              <Route path="/documents" element={<DocumentsM />}></Route>
            </Routes>
          </div>

        </>
      }
    </div>
  );
}

export default App;

import React, { useState } from "react";
import s from "./pageM.module.css";
import wisecity from "./../../image/Coming_Soon_Button.png";
import pic from "../../image/stock-photo2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { selectLog, selectMenu, selectReg } from "../../redux/selectors";
import { useEffect } from "react";
import { authCheck } from "../../redux/slices/regSlice";
import Log from "../regLog/log/Log";
import Reg from "../regLog/reg/Reg";
import { goRegLog } from "../../redux/slices/navigateSlice";
import Page2 from "../pages/Page2";
/*import PopUp from "../pop/PopUp";*/
//import { selectMenu } from '../../redux/selectors';

export default function MainPageM() {
  //const menu = useSelector(selectMenu);
  const regShow = useSelector(selectReg);
  const logShow = useSelector(selectLog);
  const menu = useSelector(selectMenu);
  const [pos, setPos] = useState([{}, {}]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authCheck());
  }, [dispatch]);
  const close = () => {
    dispatch(goRegLog({ registr: false, login: false }));

  }
  useEffect(() => {
    if (logShow) {
      setTimeout(() => {
        setPos([{ right: 0 }, {}]);
      }, 50);
    }
    if (regShow) {
      setTimeout(() => {
        setPos([{}, { right: 0 }]);
      }, 50);
    }
  }, [logShow, regShow]);

  return (
    <div style={menu ? {opacity: "0.3"} : {opacity: "1"}} className={s.container}>
   {/*<PopUp />*/}
      <img className={s.wisecityPic} src={wisecity} alt="" />
      <div className={s.headerTop}>
        {logShow && <Log style={pos[0]} close={close} />}
        {regShow && <Reg style={pos[1]} close={close} />}
        <div style={(logShow || regShow) ? { opacity: 0.2 } : undefined}>
        <div className={s.headerContent}>
          <p className={s.headerTextLeft}>Ваш следующий проект начинается </p>
          <div className={s.headerTextBlock}>
            <p className={s.headerTextC}>c</p>
            <p className={s.headerTextP}>Премекс</p>
            <p className={s.headerTexB}>Бел</p>
          </div>
        </div>
        <p className={s.subtitle}>Умные технологии измерения будущего!</p>
      </div>

      <div className={s.promo}>
        <img className={s.promoPic} src={pic} alt="" />
  </div>
      <Page2 />
    </div>
    </div>
  );
}

import axios from "axios";
import { urlServer } from "../config";
//let AUTH_TOKEN =  localStorage.getItem("token");

const instance = axios.create({
  baseURL: urlServer + 'user/'
 // baseURL: 'http://192.168.0.103:5000/premex/user/'
})
//instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export default class RegLogService {

    static async regUser({ email, password, tel, company, unp, role }) {
        const result = await instance.post('registration', { email, password, tel, company, unp, role });
       
        return result.data
    }
    static async authCheck (token) {
        instance.defaults.headers.common['Authorization'] = token;
        const result = await instance.get('auth');
        return result.data
    }
    static async logUser({ email, password }) {
        const result = await instance.post('login', { email, password });
        return result.data
    }
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ChatService from '../../api/chatService';


export const fetchMessage = createAsyncThunk(
    'chat/fetchMessage',
    async (message, { rejectWithValue, dispatch, getState }) => {


        try {
            await ChatService.sendMessage(message);

        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
)


const setError = (state, action) => { //обработка ошибки
    state.status = 'rejected';
    state.error = action.payload;
}

const chat = createSlice({
    name: 'chat',
    initialState: {
        status: null,
        error: null
    },
    reducers: {

    },
    extraReducers: {
        [fetchMessage.pending]: (state, action) => {
            state.status = 'loading';

        }, // метод жизненного цикла - загрузка 
        [fetchMessage.fulfilled]: (state, action) => {
            state.status = null;
        }, // метод жизненного цикла - успешное получение данных
        [fetchMessage.rejected]: setError,
        // метод жизненного цикла - неполадка



    }
})

export const { extraReducers } = chat.actions
export default chat.reducer
import React from "react";
import card from "../../../image/card.png";
import profile from "../../../image/profil.png";

import { useNavigate } from "react-router-dom";
import { selectAuth, selectBasket, selectRegUser } from "../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import s from "./navBarM.module.css";
import { goRegLog, goMenu } from "../../../redux/slices/navigateSlice";
import { basketShowReducer, changeBasket } from "../../../redux/slices/basketSlice";
import { registrAuth, registrReducer } from "../../../redux/slices/regSlice";
import { HiOutlineBars3 } from "react-icons/hi2";
import { HiXMark } from "react-icons/hi2";

export default function NavBarM() {
  const dispatch = useDispatch();  
  let auth = useSelector(selectAuth);
  let { email, role } = useSelector(selectRegUser);
  const basket = useSelector(selectBasket);
  const navigate = useNavigate();
  const showBasket = () => {
    navigate("/");
    dispatch(basketShowReducer(true));
  };
  let shortEmail = email.slice(0, email.indexOf('@'));
  if (shortEmail.length > 7 ) {
     shortEmail = email.slice(0, 7)+'...'
  } 
  const onClick = () => {
    dispatch(registrReducer({ email: "", role: "" }));
    dispatch(registrAuth(false))
    dispatch(changeBasket([]));
    localStorage.clear("tokenPremex");
  };

  return (
    <div className={s.container}>
      <div className={s.headerTop}>
        <div onClick={() => navigate("/")} className={s.logo}></div>

        <div className={s.menuPhone}>+375 29 305-68-33</div>
        <div className={s.menuEnter}>
          {auth ? (
            <>
              <div className={s.cabinet}>
                <h5>{shortEmail}</h5>
                 <HiXMark className={s.close} onClick={onClick} />
               
                
              </div>
            </>
          ) : (
            <div className={s.regLog}>
              <div
                className={s.enter}
                onClick={() => {
                  dispatch(goRegLog({ registr: false, login: true }));
                  navigate("/");
                }}
              >
                <img className={s.profile} src={profile} alt="" />
              </div>{" "}
            </div>
          )}
          <div className={s.blockCard}>
            <img onClick={showBasket} className={s.card} src={card} alt="" />
            {basket.length > 0 && <div className={s.cardCircle}></div>}
          </div>
          <HiOutlineBars3 className={s.burgerLine}   onClick={() => {
              dispatch(goMenu(true));
              navigate("/");
            }}/>
    
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
//import logo from '../../image/Logo.png';
//import burger from '../../image/burger_black.png'
import s from "./contacts.module.css";
import Connect from "../futter/Connect";
//import { useNavigate } from 'react-router-dom';

const Contacts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //const navigator = useNavigate();

  return (
    <div>
      <div className={s.contacts}>
        <div className={s.container}>
          <div className={s.blockContacts}>
            <div className={s.usContacts}>
              <div className={s.usContactsLeft}>
                <h1>Наши контакты</h1>
                <div className={s.title}>
                  <h3> Обращайтесь к нам, </h3>
                  <h3>
                    {" "}
                    если вы хотите сотрудничать или просто посоветоваться.
                  </h3>
                </div>
              </div>
            </div>

            <div
              className={s.connect}          
            >
              <Connect className={s.connectQuestion} />
            </div>
          </div>
        </div>
       
        <div className={s.futerContacts}>
          <div className={s.container}>
            <div className={s.itemsContacts}>
              <div className={s.item}>
                <p className={s.text}>Общие вопросы</p>
                <p className={s.mail}>info@premexbel.by</p>
                <p className={s.phone}>+375 29 305-68-33</p>
                <p className={s.phone}>+375 17 344-55-23</p>
              </div>
              <div className={s.item}>
                <p className={s.text}>Отдел продаж</p>
                <p className={s.mail}>+375 44 512-81-12</p>
                <p className={s.phone}>+375 29 696-31-91</p>
                <p className={s.phone}>+375 29 374-20-65</p>
              </div>
              <div className={s.item}>
                <p className={s.text}>Техподдержка</p>
                <p className={s.phone}>+375 29 699-54-52</p>
                <p className={s.textBux}>Бухгалтерия</p>
                <p className={s.phone}>+375 17 344-54-53</p>
              </div>

              <div className={s.item}>
                <div className={s.office}>
                  <p className={s.text}>Офис</p>
                  <p className={s.textAdress}>
                    220037,пер. Козлова, 7а, пом. 8,
                  </p>
                  <p className={s.textAdress}>г. Минск, Республика Беларусь</p>
                </div>
                <div className={s.store}>
                  <p className={s.text}>Склад</p>
                  <p className={s.textAdress}>220037, ул. Уральская, 6а</p>
                  <p className={s.textAdress}>г. Минск, Республика Беларусь</p>
                </div>
              </div>
            </div>
          </div>
          <iframe
            title="map"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A79d602378e0bb1c7f0297949047bd538e6346bf43057038dcdd5f7b7b4765304&amp;source=constructor"
            frameborder="0"
          ></iframe>
          <div className={s.futterBottom}>
            <p className={s.futterCopi}>
              &#169; OxSeDu 2023 Все права защищены
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

import React from "react";
import s from "./preloder.module.css";

const Preloder = () => {
  const styles = [s.p1, s.r2, s.e3, s.m4, s.e5, s.k6, s.s7, s.b8, s.e9, s.l10];

  return (
    <>
      <div className={s.preloder}>
        <p className={styles[0]}>п</p>
        <p className={styles[1]}>р</p>
        <p className={styles[2]}>е</p>
        <p className={styles[3]}>m</p>
        <p className={styles[4]}>е</p>
        <p className={styles[5]}>к</p>
        <p className={styles[6]}>с</p>
        <p className={styles[7]}>б</p>
        <p className={styles[8]}>е</p>
        <p className={styles[9]}>л</p>
      </div>
    </>
  );
};

export default Preloder;

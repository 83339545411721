import React from "react";
import s from "./sliderBack.module.css";
import pic from "../../image/pic3.png";
import pic2 from "../../image/h2o.png";
import pic3 from "../../image/city.png";
import pic4 from "../../image/elfgran.png";
import pic6 from "../../image/desh.png";
import { Link } from "react-router-dom";
export default function SliderBack() {
  let picTop = [pic, pic2, pic3, pic4, pic6];
  /*let urlTop = [
    "https://pulsarm.ru/",
    "https://formulah2o.by/",
    "https://g-yuta.by/",
    "https://elf-techno.by/",
    "https://minskgas.by/",
  ];*/

  const partTop = picTop.map((value, index) => {
    return (
      <Link key={value + index + index} style={{cursor:'none'}}>
        <img src={value} alt={index} />
      </Link>
    );
  });

  return (
    <div className={s.logos}>
      <div className={s.slide}>{partTop}</div>
      <div className={s.slide}>{partTop}</div>
    </div>
  );
}

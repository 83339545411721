import React from 'react'
import Catalog from './Catalog'
import Subcatalog from './Subcatalog'
import s from './catalog.module.css'

export default function GeneralCatalog({size}) {
  return (
    
    <div className={s.generalCatalog}>
      <Catalog />
      <Subcatalog size={size}/>
      
    </div>
 
  )
}
